import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Products from './components/Products';
import Services from "./components/Services";
import Careers from "./components/Careers";
import ContactUs from "./components/ContactUs";

import {initializeApp} from "firebase/app";

function App() {

  const firebaseConfig = {

    apiKey: "AIzaSyC86uNNc0LBrHlizUqGX8jJObmpNaxkKO0",

    authDomain: "cubixwebsite.firebaseapp.com",

    databaseURL: "https://cubixwebsite-default-rtdb.asia-southeast1.firebasedatabase.app",

    projectId: "cubixwebsite",

    storageBucket: "cubixwebsite.appspot.com",

    messagingSenderId: "128493793842",

    appId: "1:128493793842:web:a6e219efabd58106adc22b"

  };


  // Initialize Firebase

  const app = initializeApp(firebaseConfig);

  return (
    <Router>
      <Routes>

        <Route path="/" element={
          <Home />
        } />


        <Route path="/about_us" element={
          <AboutUs />
        } />

        <Route path="/products" element={
          <Products />
        } />


        <Route path="/services" element={
          <Services />
        } />

        <Route path="/careers" element={
          <Careers />
        } />


        <Route path="/contact_us" element={
          <ContactUs />
        } />

      </Routes>
    </Router>
  );
}

export default App;
