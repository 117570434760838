import React from "react";

import ic_checkmark_meeting_popup from "../img/ic_checkmark_meeting_popup.png";
import ic_failure_popup from "../img/ic_failure_popup.png";
import "../css/SuccessFailurePopupHomePage.css";

function SuccessFailurePopupHomePage(props) {
  const {
    showHideResultPopup,
    resultStatus } = props;

  const closeSuccessContainer = () => {
    showHideResultPopup();
  };
  const closeFailureContainer = () => {
    showHideResultPopup();
  };
  return (
    <div className="outer_container_success_failure_popup">
      {
        resultStatus == "success" ? (
          <div className="success_failure_container_Component">
            <img src={ic_checkmark_meeting_popup} />
            <p className="mt-4">Your message has been delivered.</p>

            <button
              className="ok_button_success_meeting_popup"
              onClick={closeSuccessContainer}
            >
              OK
            </button>
          </div>
        ) : null}

      {
        resultStatus == "failure" ? (
          <div className="failure_container_Component">
            <img src={ic_failure_popup} />
            <p className="mt-4">Something went wrong, Message not sent. Please call</p>
            <a href="tel:+971504335711" onClick={closeFailureContainer} className="button-call-request-meeting-popup">+971504335711</a>
            <span onClick={closeFailureContainer} className="button-call-request-meeting-popup close-button-failure-popup-below-call mt-2">Close</span>
          </div>
        ) : null}
    </div>
  );
}

export default SuccessFailurePopupHomePage;
