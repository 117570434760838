import React from "react";
import "../css/Careers.css";
import cubix_ball_logo from "../img/cubix_ball_logo.png";
import services_bottom_img_1 from "../img/services_bottom_img_1.png";
import services_bottom_img_2 from "../img/services_bottom_img_2.png";
import services_bottom_img_3 from "../img/services_bottom_img_3.png";
import careers_img_bg from "../img/careers_image_bg.jpg";
import ic_forward_next from "../img/ic_forward_next.png";
import Header from "./Header";
import Footer from "./Footer";

function Careers() {
    return (
        <div className="Careers-root-container">
            
            <div className="careers_header_container">
                <div className="container">

                    <Header />

                    <h4 className="about_us_header_label">CAREERS</h4>
                    <div className="careers_top_container d-flex flex-column flex-md-row">
                        <img src={careers_img_bg} />
                        <div className="d-flex flex-column">
                            <h5 className="ms-4">Join our Team,</h5>
                            <p className="careerpageparagraph mt-4 ms-4">
                                Why you join this team? Person who eager to grow with a
                                professional platform. Person who learns something new from the
                                professional team. Person who wants to smiles always.
                            </p>
                            <p className="careerpageparagraph ms-4">
                                We are the pathfinder in your journey, the best friend in your
                                career growth and the true companion in your success.
                            </p>
                        </div>
                    </div>

                    <h5 className="our_vacancy_label">Our Vacancies</h5>
                    <ul className="our_vacancy_list">
                        <li className="card job-list-item">
                            <a href="https://ae.indeed.com/viewjob?t=technical+support+software&jk=f5b33ee92cc382a0&_ga=2.154132600.1362548095.1633338022-1189043640.1630850136">
                                <span className="job-name">Technical Support (software) </span>
                                <img src={ic_forward_next} className="job_list_forward_arrow" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Careers;