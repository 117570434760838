import React from "react";
import ic_facebook from "../img/ic_facebook.svg";
import "../css/Footer.css";
import ic_youtube from "../img/ic_youtube.svg";
import ic_linkedin from "../img/ic_linkedin.svg";

function Footer() {
    return (
        <div className="Footer-root-container container-fluid">


            <div className="Footer-main-content container d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <h4>Products</h4>
                    <label>XPERT CUBIX</label>
                    <label>C-Pulse</label>
                    <label>CTRIX</label>
                    <label>CWHEELS</label>
                    <label>CPAYZ</label>
                    <label>CREAL</label>
                </div>
                <div className="d-flex flex-column">
                    <h4>Solutions</h4>
                    <label>Autoparts & automobile</label>
                    <label>Logistics</label>
                    <label>Field Service</label>
                    <label>Warehouse Management</label>
                    <label>Projects & Service</label>
                    <label>Construction</label>
                    <label>Fire & Safety</label>
                    <label>Foodstuff</label>
                    <label>Trading & Distribution</label>
                </div>
                <div className="d-flex flex-column quick-link-box">
                    <h4>Quick Links</h4>
                    <a href="/about_us">
                        <label>About Us</label>
                    </a>
                    <a href="/products">
                        <label>Products</label>
                    </a>
                    <a href="/services">
                        <label>Services</label>
                    </a>
                    <a href="/careers">
                        <label>Careers</label>
                    </a>
                    <a href="/contact_us">
                        <label>Contact Us</label>
                    </a>
                    
                </div>
                {/* <div className="d-flex flex-column Footer-icon-social_media">
                    <h4>Find Us On</h4>
                    <label className="mt-2 d-flex align-item-center"><img src={ic_facebook} className="me-3" />Facebook</label>
                    <label className="mt-2 align-item-center"><img src={ic_youtube} className="me-3" />Youtube</label>
                    <label className="mt-2 d-flex align-items-center"><img src={ic_linkedin} className="me-3" />LinkedIn</label>
                </div> */}
            </div>


        </div>
    )
}

export default Footer;