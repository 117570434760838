import React, { useState } from "react";
import "../css/ContactUs.css";
import contact_us_banner_image from "../img/contact_us_banner_image.svg";
import axios from "axios";
import SuccessFailurePopupHomePage from "./SuccessFailurePopupHomePage";
import Header from "./Header";
import { getDatabase, ref, push, get, set, child, update, remove } from "firebase/database";
import Footer from "./Footer";

function ContactUs() {

    const [resultStatus, setResultStatus] = useState(null);

    const [showResultPopup, setShowResultPopup] = useState(false);

    // initialize database
    const db = getDatabase();

    // referenece to database root
    const dbRef = ref(db);

    // reference to People child root

    const peopleDbRef = ref(db, "People");


    const [messageSubmitClicked, setMessageSubmitClicked] = useState(false);

    const initialValuesOfFormContactPage = {
        user_name_contact_us: "",
        user_phone_contact_us: "",
        user_email_contact_us: "",
        user_message_contact_us: "",
    };
    const [stateOfFormContactPage, setStateOfFormContactPage] = useState(
        initialValuesOfFormContactPage
    );

    const errorInitialValuesContactPage = {
        error: "",
        name_error: "",
        email_error: "",
    };
    const [valuesContactPage, setValuesContactPage] = useState(
        errorInitialValuesContactPage
    );

    const validateEmail = (email) => {
        const pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(email.toLowerCase())) {
            // if is correct print out "valid email"
            console.log("valid email");
            return true;
        } else {
            //else print "invalid email"
            console.log("invalid email");
            return false;
        }
    };

    const validateInputContactPage = () => {
        console.log("inside validateInputContactPage");

        if (stateOfFormContactPage.user_name_contact_us.length == 0) {
            console.log("inside user_name_contact_us");
            setValuesContactPage((prev) => ({
                ...valuesContactPage,
                name_error: "Please enter Name",
                email_error: prev.email_error,
            }));
        }
        if (stateOfFormContactPage.user_phone_contact_us.length == 0) {
            console.log("inside user_phone_contact_us");
            setValuesContactPage((prev) => ({
                ...valuesContactPage,

                name_error: prev.name_error,
                email_error: prev.email_error,
            }));
        }
        if (stateOfFormContactPage.user_email_contact_us.length == 0) {
            console.log("inside user_email_contact_us");
            setValuesContactPage((prev) => ({
                ...valuesContactPage,

                name_error: prev.name_error,
                email_error: "Please enter Email Address",
            }));
        }

        if (
            stateOfFormContactPage.user_name_contact_us.length > 0 &&
            stateOfFormContactPage.user_email_contact_us.length > 0
        ) {
            if (validateEmail(stateOfFormContactPage.user_email_contact_us)) {
                changeStateOfSetMessageSubmitClicked();
                contactPageMeetingApi();
                console.log("everything correct can call contactPageMeetingApi");
            } else {
                setValuesContactPage((prev) => ({
                    ...valuesContactPage,

                    name_error: prev.name_error,
                    email_error: "Please enter a valid Email Address",
                }));
            }
        }
    };
    const inputHandlerContactPage = (e) => {
        if (e.target.name == "user_name_contact_us") {
            setValuesContactPage({
                ...valuesContactPage,
                name_error: "",
            });
        }

        if (e.target.name == "user_email_contact_us") {
            setValuesContactPage({
                ...valuesContactPage,
                email_error: "",
            });
        }

        console.log(
            `name of input inputHandlerContactPage ${e.target.name} value of input inputHandlerContactPage ${e.target.value} `
        );
        setStateOfFormContactPage((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const showHideResultPopup = () => {
        setShowResultPopup(prev => !prev)
    }

    const sendNotificationToStaffDevices = (typeOfNotification, nameOfCustomer) => {

        let apiUrl = "https://fcm.googleapis.com/fcm/send";

        let jsonBody =
        {
            "to": "/topics/cubix_saleup_notification",
            "notification": {
                "title": typeOfNotification,
                "body": nameOfCustomer
            },
            "data": {
                "story_id": "story_12345"
            }
        }


        const instance = axios.create({
            headers: {
                'Content-Type': "application/json",
                'Authorization': `key=${process.env.REACT_APP_AUTHORIZATION_KEY}`
            }
        });

        instance.post(apiUrl, jsonBody).then((res) => {

            console.log("res after sending notification ", res)
        }).catch((err) => {
            console.log("err after sending notification ")
        })
    }

    const contactPageMeetingApi = () => {

        console.log(
            " the name and number is ",
            stateOfFormContactPage.user_name_contact_us,
            stateOfFormContactPage.user_phone_contact_us,
            stateOfFormContactPage.user_email_contact_us,
            stateOfFormContactPage.user_message_contact_us,
        );

        push(peopleDbRef, {
            name: stateOfFormContactPage.user_name_contact_us,
            email: stateOfFormContactPage.user_email_contact_us,
            phone: stateOfFormContactPage.user_phone_contact_us,
            message: stateOfFormContactPage.user_message_contact_us,
            status:"pending",
            type: "contact_us"
        }).then(() => {
            changeStateOfSetMessageSubmitClicked();
            console.log("successfully sent message ");
            //clear all fields in the page
            console.log("details of state", initialValuesOfFormContactPage);

            setStateOfFormContactPage(initialValuesOfFormContactPage);

            setShowResultPopup(true)
            setResultStatus("success");

            sendNotificationToStaffDevices("contact_us", stateOfFormContactPage.user_message_contact_us)
        })
            .catch((error) => {
                changeStateOfSetMessageSubmitClicked();
                console.log("error while sending request meeting ");
                setShowResultPopup(true)
                setResultStatus("failure");
            });
    };

    const changeStateOfSetMessageSubmitClicked = () => {
        setMessageSubmitClicked((prev) => !prev);
    };

    return (
        <div className="contact_us_root_container">
            <div className="contactus_top_container">
                <div className="container">

                    <Header />
                    <h4 className="about_us_header_label">CONTACT US</h4>
                </div>
            </div>

            <div className="contactus_bottom_container position-relative">
                <img
                    src={contact_us_banner_image}
                    className="position-absolute  contact_us_illustration_image "
                />

                <div className="container d-flex p-0">
                    <div className="dummy-left-container w-50"></div>
                    <div className="d-flex flex-column w-30 contact_us_form">
                        <label>
                            Name<span className="asterisk">*</span>
                        </label>
                        <input
                            type="text"
                            name="user_name_contact_us"
                            value={stateOfFormContactPage.user_name_contact_us}
                            onChange={inputHandlerContactPage}
                        />
                        <label className="form-error-label-popup">
                            {valuesContactPage.name_error}
                        </label>

                        <label>
                            Email<span className="asterisk">*</span>
                        </label>
                        <input
                            type="email"
                            name="user_email_contact_us"
                            onChange={inputHandlerContactPage}
                            value={stateOfFormContactPage.user_email_contact_us}
                        />
                        <label className="form-error-label-popup">
                            {valuesContactPage.email_error}
                        </label>
                        <label>
                            Phone
                        </label>
                        <input
                            type="number"
                            name="user_phone_contact_us"
                            value={stateOfFormContactPage.user_phone_contact_us}
                            onChange={inputHandlerContactPage}
                        />

                        <label>Message</label>
                        <textarea
                            id="id_user_message"
                            name="user_message_contact_us"
                            rows="4"
                            cols="50"
                            value={stateOfFormContactPage.user_message_contact_us}
                            onChange={inputHandlerContactPage}
                        ></textarea>

                        <button
                            className="btn btn-primary submit_contact_form"
                            onClick={validateInputContactPage}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className="container  contact_us_map_address shadow d-flex flex-column flex-md-row">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.7546872922776!2d55.38184541437987!3d25.278836383858227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c39aeb9c9f9%3A0xe5400c5f96a282c1!2sCUBIX%20IT%20SOLUTIONS%20LLC!5e0!3m2!1sen!2sae!4v1631975870805!5m2!1sen!2sae"
                    width="500"
                    height="280"
                    allowFullScreen=""
                    loading="lazy"
                    className="map_container_contact_us"
                ></iframe>
                <div className="address_container_contact_us">
                    <div className="address_container_contact_us_inner">
                        <p>
                            CUBIX IT SOLUTIONS LLC <br />
                            AL NAZRIYA BLDS.
                            <br />
                            FLAT NO. 129
                            <br />
                            AL QUSAIS . DUBAI . UAE
                        </p>
                    </div>
                    <div className="address_container_contact_us_inner">
                        <a className="phone_number" href="tel:+97142677221">+97142677221</a>
                    </div>
                    <div className="address_container_contact_us_inner mt-1">
                        <a className="phone_number" href="tel:+971504335711">+971504335711</a>
                    </div>
                    <div className="address_container_contact_us_inner mt-1">
                        <p>info@cubixitsolutions.com</p>
                    </div>
                </div>
            </div>

            {messageSubmitClicked ? (
                <div className="outer_container_loader_after_button_click">
                    <div className="loader_contact_us_page"></div>{" "}
                    <p className="text-white ms-4">Sending Message, please wait</p>
                </div>
            ) : null}

            {
                showResultPopup &&
                <SuccessFailurePopupHomePage
                    showHideResultPopup={showHideResultPopup} resultStatus={resultStatus} />
            }

            <Footer />
        </div>
    );
}

export default ContactUs;