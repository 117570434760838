import React from "react";
import "../css/About.css";
import about_us_network_image from "../img/about_us_network_image.png";
import mobile_view_about_us from "../img/mobile_view_about_us.svg";
import through_cubix_image from "../img/through_cubix_image.jpg";
import Header from "./Header";
import Footer from "./Footer";

function AboutUs() {
    return (
        <div className="About-root-container">

            <div className="about_header_container">

                <div className="container">

                    <Header />


                    <img
                        src={mobile_view_about_us}
                        className="about_us_network_image  d-block d-lg-none"
                    />
                    <img
                        src={about_us_network_image}
                        className="about_us_network_image img-fluid d-none d-lg-block"
                    />
                </div>
            </div>

            <div className="about_us_main_content container">

                <h4 className="about_us_header_label">About Cubix</h4>

                <p>
                    CUBIX IT SOLUTIONS LLC consists of highly experienced manpower
                    resources with the purpose of offering software solutions and services
                    to various industries. Through them Cubix makes its mark as a provider
                    in the area of the global innovative software solutions. We at CUBIX,
                    pushes the limit to enable progressive businesses to transform and
                    gain competitive advantage, through the expert delivery of innovative
                    software products and tailor-made software.
                </p>
                <p>
                    CUBIX’s multi-disciplinary engineering capabilities range from initial
                    R&amp;D all the way to completion and handover. Our software solutions
                    are chosen because of their reliability and ability to fit most
                    organizations operating and business processes. Adhere to that, we
                    have been selected for many global enterprises roll out that involve
                    big corporate sectors. With our innovative solutions, our customers
                    can enjoy full platform integration or specific components. And that
                    makes CUBIX unique in the industry.
                </p>
            </div>
            <div className="about_us_square-div-container">
                <div className="container">
                    <div className="top-bar flex-column flex-md-row">
                        <div className="top-bar-left p-4">
                            <h3>Mission</h3>
                            <p>
                                Our mission is to enhance the business growth of our customers
                                by delivering optimal solutions with quality and services. We
                                always try to improve the quality of our products and services
                                by exploring innovative ideas. We place a high value on
                                transparency and extensive communication to make sure the needs
                                and expectations of every client are met 100%.
                            </p>
                        </div>
                        <div className="top-bar-center d-none d-md-block">
                            <hr />
                        </div>
                        <div className="top-bar-right p-4">
                            <h3>Vision</h3>
                            <p>
                                CUBIX aims to become an innovative software company that
                                delivers end solution providers in the global industry with
                                self-activeness and modern technology.
                            </p>
                        </div>
                    </div>
                    <hr className="about_us_center_line" />
                    <div className="bottom-bar flex-column flex-md-row">
                        <div className="bottom-bar-left p-4">
                            <h3>Expertise</h3>
                            <p>
                                Our team of Professionals with 15+ years of experience in ERP
                                solutions, Accounting and finacial sector will give you, tailor
                                made software solutions.
                            </p>
                        </div>
                        <div className="bottom-bar-center d-none d-md-block">
                            <hr />
                        </div>
                        <div className="bottom-bar-right p-4">
                            <h3>The future</h3>
                            <p>
                                300+ clients spreaded across 8 countries, 6 industries helped us
                                to shape our future products leveraging the possibilities of AI{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="though-cubix-div-container">
                <div className="container  d-flex flex-column  flex-md-row">
                    <img
                        src={through_cubix_image}
                        className="through_cubix_right-left-image img-fluid"
                    />
                    <div className="through_cubix_right">
                        <div className="d-flex">
                            <hr className="through-cubix-line-label" />
                            <h5>Core Values</h5>
                        </div>
                        <ul className="through-cubix-list-container">
                            <li>
                                <span>Think Big</span> - Think of the positive and realistic
                                ways to get all things possible.
                            </li>
                            <li>
                                <span>Take Challenges</span> - Take chances and go forward.
                            </li>
                            <li>
                                <span>Commitment</span> -Commit to deliver the end result
                            </li>
                            <li>
                                <span>Professionalism</span> - Pursuing uniqueness and a high
                                degree of professionalism{" "}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="about_us_bottom_bar container d-flex flex-column  flex-md-row">
                <span>increase safety </span>
                <span>maximize efficiency</span>
                <span>improve revenue</span>
                <span>manage growth</span>
                <span>reduce costs</span>
            </div>

            <Footer />
        </div>
    );
}

export default AboutUs;