import React from "react";
import "../css/Products.css";
import through_cubix_image from "../img/cubix-erp-page-image.png";
import Header from "./Header";
import Footer from "./Footer";
import ic_pro_1 from "../img/pro1.jpg";
import ic_pro_2 from "../img/cpulse.png";
import ic_pro_3 from "../img/citrix.png";
import ic_pro_4 from "../img/cubixerp.png";
import ic_pro_5 from "../img/cwheels.png";
import ic_pro_6 from "../img/cpayz.png";
import ic_pro_7 from "../img/creal.png";

function Products() {
    return (
        <div className="Products-root-container">

            <div className="container">
                <Header />
            </div>

            <div>
                <div className="container product_box_container">

                    <div className="product_single_box">
                        <img src={ic_pro_1} className="product_icon" />
                        <h4>Xpert Cubix</h4>
                        <p>Streamline your financial operations with our advanced Accounting & ERP Software. Seamlessly manage your company's finances, including accounts receivable, accounts payable, general ledger, and inventory. Make informed decisions and optimize your business processes.</p>
                    </div>

                    <div className="product_single_box">
                        <img src={ic_pro_2} className="product_icon" />
                        <h4>CPULSE</h4>
                        <p>Improve patient care and streamline administrative processes with our comprehensive Healthcare Management Software. From patient scheduling and electronic medical records (EMR) to billing and insurance claim management, our solution simplifies healthcare workflows. </p>
                    </div>

                    <div className="product_single_box">
                        <img src={ic_pro_3} className="product_icon" />
                        <h4>CTRIX</h4>
                        <p>Enhance your customer relationships and drive sales growth with our powerful CRM Software. Our solution empowers your sales team to effectively manage leads, track customer interactions, and nurture relationships. </p>
                    </div>


                    <div className="product_single_box">
                        <img src={ic_pro_5} className="product_icon" />
                        <h4>CWHEELS</h4>
                        <p>Efficiently manage your automotive garage with our comprehensive Garage Management Software. From appointment scheduling and inventory management to job tracking and invoicing, our software automates your garage operations.</p>
                    </div>

                    <div className="product_single_box">
                        <img src={ic_pro_6} className="product_icon" />
                        <h4>C-PAYZ</h4>
                        <p>Simplify your payroll and human resources tasks with our comprehensive Payroll and HR Management Software. From accurate payroll calculations to employee data management, our software streamlines your HR operations. </p>
                    </div>

                    <div className="product_single_box">
                        <img src={ic_pro_7} className="product_icon" />
                        <h4>C-REAL</h4>
                        <p>Optimize your real estate business with our feature-rich Real Estate Software. From property listings and sales management to contract and document management, our software streamlines your operations. </p>
                    </div>

                </div>
            </div>

            <div className="cubix-erpsecond-div-container">
                <div className="container">
                    <div className="cubix_erp_item">
                        <span>1</span>
                        <div>
                            <h6>Financial Management &amp; Accounting</h6>
                            <span>
                                Helps monitor and operate your business financial performance
                                utilizing real-time dashboards with expenses, key revenues and
                                receivables metrics, optimize cash management and easily create
                                various financial reports in accordance with the requirements of
                                the company.
                            </span>
                        </div>
                    </div>
                    <div className="cubix_erp_item">
                        <span>2</span>
                        <div>
                            <h6>Business Intelligence &amp; Reporting</h6>
                            <span>
                                BI is data transformed into actionable insights that support
                                better, faster decision making. Xpert Cubix can be your
                                organization’s eyes and ears, allowing you to monitor your
                                chosen key performance indicators and make changes when those
                                KPIs aren’t where they should be.
                            </span>
                        </div>
                    </div>
                    <div className="cubix_erp_item">
                        <span>3</span>
                        <div>
                            <h6>Inventory Management &amp; Operations</h6>
                            <span>
                                Plan order quantities, monitor goods receipts, and store and
                                transport materials: Xpert Cubix helps you to coordinate your
                                flow of goods so that you can speed up your stock turnover and
                                guarantee high readiness to deliver while simultaneously
                                reducing your capital commitment.
                            </span>
                        </div>
                    </div>
                    <div className="cubix_erp_item">
                        <span>4</span>
                        <div>
                            <h6>Sales Management</h6>
                            <span>
                                With Xpert Cubix, you can manage your sales processes with ease,
                                from pricing and bid creation to order processing, order
                                controlling, forecasting and early warning systems. You can
                                reduce errors, identify trends at an early stage, plan
                                dynamically and calculate accurately.
                            </span>
                        </div>
                    </div>
                    <div className="cubix_erp_item">
                        <span>5</span>
                        <div>
                            <h6>Be Anywhere</h6>
                            <span>
                                Xpert Cubix improve efficiency and optimize the essential parts
                                of your business by giving users access to the important data
                                they need anywhere, anytime.
                            </span>
                        </div>
                    </div>
                    <div className="cubix_erp_item">
                        <span>6</span>
                        <div>
                            <h6>Business Process Management</h6>
                            <span>
                                Every day, your employees carry out a large number of routine
                                activities. With professional workflow management, these
                                processes can be improved, standardized and simplified. Fixed,
                                defined workflows shorten processing times, reduce errors and
                                guarantee reliable release processes.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="though-cubix-div-container">
                <div className="container">
                    <div className="through_cubix_right about_bottom_section p-4">
                        <div className="d-flex">
                            <hr className="through-cubix-line-label" />
                            <h5>Tame Your Operational Cost With Xpert Cubix!</h5>
                        </div>
                        <p>
                            Bring down total ownership and operational cost with the best ERP
                            software in the GCC. Easily manage your customers, vendors,
                            partners, suppliers and much more along with your finances, supply
                            chain, inventory, manufacturing, etc. on the run with one of the
                            esteemed ERP companies in GCC. Xpert Cubix blends the power of
                            desktop application and mobile to drive operational efficiency for
                            a business irrespective of the size or vertical it operates. Are
                            you a growing business? If yes, then Xpert Cubix ERP Software is
                            the perfect-pick-me-up for you to not only streamline critical
                            business processes, but also help your business grow rapidly.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Products;