import React from "react";
import "../css/Services.css";
import cubix_ball_logo from "../img/cubix_ball_logo.png";
import services_bottom_img_1 from "../img/services_bottom_img_1.png";
import services_bottom_img_2 from "../img/services_bottom_img_2.png";
import services_bottom_img_3 from "../img/services_bottom_img_3.png";
import Header from "./Header";
import Footer from "./Footer";

function Services() {
    return (
        <div className="Services-root-container">


            <div className="services_header_container">

                <div className="services_header_container_inner">

                    <div className="container">
                        <Header />

                        <h4 className="about_us_header_label">SERVICES</h4>

                        <h5 className="mt-4">Custom Software Development</h5>
                        <p className="ms-4">
                            Using deep domain and advanced technology expertise of our
                            developers, we create intelligent software solutions that drive
                            meaningful change with a strategic vision. Why your business can
                            benefit from investing in a customized tailor-made solution?
                            Probably the most important reason to invest in custom software
                            development is to develop a product that addresses your exact
                            needs.
                        </p>
                        <ul>
                            <li>You will get Targeted Solutions</li>
                            <li>Greater Scalability</li>
                            <li>Possibility to integrate your other applications.</li>
                            <li>Increased Reliability</li>
                        </ul>

                        {/* lgg */}
                        <h5 className="mt-4">Mobile APP Development</h5>

                        <ul>
                            <li>Native Mobile App Development</li>
                        </ul>
                        <p className="ms-4">
                            We are developing high-performance, secure, and reliable native
                            mobile applications for various industries to provide the ultimate
                            user experience and helping them enhance their business.
                        </p>

                        {/* sdfsd */}
                        <ul>
                            <li>Hybrid Mobile App Development</li>
                        </ul>
                        <p className="ms-4">
                            We create hybrid mobile apps with JavaScript, HTML, and CSS, to
                            allow businesses to reach more customers on any mobile device.
                        </p>

                        {/* sfgfd */}
                        <h5 className="mt-4">Software Maintenance Services</h5>
                        <p className="ms-4">
                            We are pledged to provide great support to our clients, allowing
                            them to use and benefit from the software solution they have
                            acquired after investing on our solutions.{" "}
                        </p>

                        {/* sfs */}
                        <h5 className="mt-4">API Development</h5>
                        <p className="ms-4">
                            We develop custom APIs for various business requirements that
                            facilitating communication between your apps &amp; other
                            applications and enable flexible integrations and customization of
                            existing software products
                        </p>

                        {/* mobile version of chart  */}
                        <div className="services_chart_mobile_container d-block d-md-none">
                            <img
                                src={cubix_ball_logo}
                                className="services_ball_logo_cubix_mobile"
                            />
                            <hr className="services_chart_center_seperator_mobile" />
                            <div className="chart_item chart_item_mobile">
                                <span>Financial Management &amp; Accounting</span>
                                <hr />
                            </div>
                            <div className="chart_item chart_item_mobile">
                                <span>Business Intelligence &amp; Reporting</span>
                                <hr />
                            </div>
                            <div className="chart_item chart_item_mobile">
                                <span>Sales Management</span>
                                <hr />
                            </div>
                            <div className="chart_item chart_item_mobile">
                                <span>Inventory Management &amp; Operations</span>
                                <hr />
                            </div>
                            <div className="chart_item chart_item_mobile">
                                <span>Business Process Management</span>
                            </div>
                        </div>

                        {/* desktop verion of chart */}

                        <div className="services_chart_container d-none d-md-block">
                            <div className="services_chart_top_bar">
                                <div className="chart_item">
                                    <span>Financial Management &amp; Accounting</span>
                                    <hr />
                                </div>
                                <div className="chart_item">
                                    <span>Business Intelligence &amp; Reporting</span>
                                    <hr />
                                </div>
                                <div className="chart_item">
                                    <span>Sales Management</span>
                                    <hr />
                                </div>
                            </div>
                            <div className="services_chart_center_bar">
                                <hr className="services_chart_center_seperator" />

                                <div className="chart_item text-center">
                                    <hr className="mx-auto my-0" />
                                    <img
                                        src={cubix_ball_logo}
                                        className="services_ball_logo_cubix"
                                    />
                                    <hr className="mx-auto my-0" />
                                </div>

                                <hr className="services_chart_center_seperator" />
                            </div>

                            <div className="services_chart_bottom_bar">
                                <div className="chart_item  ">
                                    <hr className="mt-0" />
                                    <span>Inventory Management &amp; Operations</span>
                                </div>
                                <div className="chart_item">
                                    <hr className="mt-0" />
                                    <span>Business Process Management</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services_bottom_container">
                <div className="container">
                    <div className="d-flex mt-4 d-flex flex-column flex-md-row">
                        <img src={services_bottom_img_1} />
                        <div className="mx-4 mt-4 mt-md-0">
                            <h5>Quick onboarding</h5>
                            <p>
                                Reduce training time and disruptions with a similar look and
                                feel of—and integration with—Microsoft Outlook, Word, and Excel.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex mt-4 flex-column flex-md-row">
                        <img src={services_bottom_img_2} />
                        <div className="mx-4 mt-4 mt-md-0">
                            <h5>Become an Intelligent Enterprise today</h5>
                            <p>
                                We can help you transform your business into an Intelligent
                                Enterprise. See how you can redefine the customer experience,
                                deliver a step change in productivity, and inspire total
                                workforce engagement – to achieve game-changing outcomes
                            </p>
                        </div>
                    </div>
                    <div className="d-flex mt-4 flex-column flex-md-row">
                        <img src={services_bottom_img_3} />
                        <div className="mx-4 mt-4 mt-md-0">
                            <h5>Proven expertise</h5>
                            <p>
                                Gain the edge with industry-specific processes, built-in
                                compliance, and best practices gained over the years of
                                experience working with companies around the globe. Get the most
                                benefit from intelligent technologies by deploying them in
                                context of your specific industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Services;