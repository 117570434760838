import React, { useState } from "react";
import "../css/Popup.css";
import ic_close_popup from "../img/ic_close_popup.svg";
import axios from "axios";
import { getDatabase, ref, push, get, set, child, update, remove } from "firebase/database";

function ScheduleDemoPopup(props) {

    const { showHideResultPopup } = props;

    // initialize database
    const db = getDatabase();

    // referenece to database root
    const dbRef = ref(db);

    // reference to People child root

    const peopleDbRef = ref(db, "People");

    const { toggleVisibilityScheduleDemoPopup, setResultStatus } = props;

    const initialUserDetails = {
        user_first_name: "",
        user_last_name: "",
        user_phone_number: "",
        user_email_address: "",
        user_message: ""
    }

    const [userData, setUserData] = useState(initialUserDetails);

    const handleInput = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }


    const sendNotificationToStaffDevices = (typeOfNotification, nameOfCustomer) => {

        let apiUrl = "https://fcm.googleapis.com/fcm/send";

        let jsonBody =
        {
            "to": "/topics/cubix_saleup_notification",
            "notification": {
                "title": typeOfNotification,
                "body": nameOfCustomer
            },
            "data": {
                "story_id": "story_12345"
            }
        }


        const instance = axios.create({
            headers: {
                'Content-Type': "application/json",
                'Authorization': `key=${process.env.REACT_APP_AUTHORIZATION_KEY}`
            }
        });

        instance.post(apiUrl, jsonBody).then((res) => {

            console.log("res after sending notification ", res)
        }).catch((err) => {
            console.log("err after sending notification ")
        })
    }

    const sendDataToAPI = () => {

        push(peopleDbRef, {
            first_name: userData.user_first_name,
            last_name: userData.user_last_name,
            user_phone_number: userData.user_phone_number,
            user_email_address: userData.user_email_address,
            user_message: userData.user_message,
            type: "schedule_demo",
            status: "pending",
        }).then(() => {
            toggleVisibilityScheduleDemoPopup();
            showHideResultPopup()
            setResultStatus("success");

            sendNotificationToStaffDevices("schedule_demo", userData.user_first_name)
        })
            .catch((error) => {
                setResultStatus("failure");
                showHideResultPopup()
            });

    }

    return (
        <div className="Popup-container-outer">
            <div className="Popup-container-inner">

                <div className="Popup-schedule-demo-container">

                    <div className="Popup-schedule-demo-container-heading">
                        <h5>Schedule A Demo</h5>
                        <img src={ic_close_popup} onClick={toggleVisibilityScheduleDemoPopup} />
                    </div>

                    <input name="user_first_name" type="text" placeholder="First Name" className="mt-4" onChange={(e) => handleInput(e)} />
                    <input name="user_last_name" type="text" placeholder="Last Name" className="mt-3" onChange={(e) => handleInput(e)} />
                    <input name="user_phone_number" type="text" placeholder="Phone Number" className="mt-3" onChange={(e) => handleInput(e)} />
                    <input name="user_email_address" type="text" placeholder="Email Address" className="mt-3" onChange={(e) => handleInput(e)} />
                    <textarea
                        name="user_message"
                        rows="4"
                        cols="50"
                        className="mt-4"
                        placeholder="Your Message"
                        onChange={(e) => handleInput(e)}
                    ></textarea>

                    <button className="mt-4" onClick={sendDataToAPI}>Submit</button>
                </div>

            </div>
        </div>
    )
}

export default ScheduleDemoPopup;