import React, { useState } from "react";
import "../css/Popup.css";
import ic_close_popup from "../img/ic_close_popup.svg";
import axios from "axios";
import { getDatabase, ref, push, get, set, child, update, remove } from "firebase/database";

function BusinessPartnershipPopup(props) {

    const { showHideResultPopup } = props;

    // initialize database
    const db = getDatabase();

    // referenece to database root
    const dbRef = ref(db);

    // reference to People child root

    const peopleDbRef = ref(db, "People");

    const { toggleVisibilityBusinessPartnershipPopup, setResultStatus } = props;

    const initialUserDetails = {
        user_name: "",
        company_name: "",
        user_phone_number: "",
        user_email_address: "",
        user_message: ""
    }

    const [userData, setUserData] = useState(initialUserDetails);

    const handleInput = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const sendDataToAPI = () => {

        push(peopleDbRef, {
            first_name: userData.user_name,
            company_name: userData.company_name,
            user_phone_number: userData.user_phone_number,
            user_email_address: userData.user_email_address,
            user_message: userData.user_message,
            type: "business_program"
        }).then(() => {
            toggleVisibilityBusinessPartnershipPopup();
            showHideResultPopup()
            setResultStatus("success");
        })
            .catch((error) => {
                setResultStatus("failure");
                showHideResultPopup()
            });

    }

    return (
        <div className="Popup-container-outer">
            <div className="Popup-container-inner">

                <div className="Popup-schedule-demo-container">

                    <div className="Popup-schedule-demo-container-heading">
                        <h5>Business Program</h5>
                        <img src={ic_close_popup} onClick={toggleVisibilityBusinessPartnershipPopup} />
                    </div>

                    <input name="user_name" type="text" placeholder="Name" className="mt-4" onChange={(e) => handleInput(e)} />
                    <input name="company_name" type="text" placeholder="Company Name" className="mt-3" onChange={(e) => handleInput(e)} />
                    <input name="user_phone_number" type="text" placeholder="Phone Number" className="mt-3" onChange={(e) => handleInput(e)} />
                    <input name="user_email_address" type="text" placeholder="Email Address" className="mt-3" onChange={(e) => handleInput(e)} />
                    <textarea
                        name="user_message"
                        rows="4"
                        cols="50"
                        className="mt-4"
                        placeholder="Your Message"
                        onChange={(e) => handleInput(e)}
                    ></textarea>

                    <button className="mt-4" onClick={sendDataToAPI}>Submit</button>
                </div>

            </div>
        </div>
    )
}

export default BusinessPartnershipPopup;