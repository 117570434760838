import React, { useState } from "react";
import ic_app_logo from "../img/ic_app_logo.png";
import "../css/Header.css";
import { NavLink } from "react-router-dom";
import ic_header_menu from "../img/ic_header_menu.svg";
import headerlogo_bg from "../img/headerlogo_bg.svg";

function Header() {

    const [showSidebar, setShowSidebar] = useState(false)

    const toggleSideBar = () => {
        setShowSidebar(prev => !prev)
    }

    return (
        <div className="Header-root-container">

            <div className="Header-inner-container container-xxl">

                <div className="Header-logo-holder">
                    <img className="Header-app-logo" src={ic_app_logo} />
                </div>

                <div className="d-block d-lg-none backgroundHeaderMenu" onClick={toggleSideBar}>
                    <img className="Header-app-menu" src={ic_header_menu} />
                </div>

                <div className="Header-menu-container d-none d-lg-block">
                    <NavLink to={"/"}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }
                    >HOME</NavLink>

                    <NavLink to={"/about_us"}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }
                    >ABOUT US</NavLink>

                    <NavLink to={"/products"}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }>PRODUCTS</NavLink>

                    <NavLink to={"/services"}
                        className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }>SERVICES</NavLink>

                    <NavLink to={"/careers"} className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active_link" : ""
                    }>CAREERS</NavLink>

                    <NavLink to={"/contact_us"} className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active_link" : ""
                    }>CONTACT US</NavLink>
                </div>

            </div>

            {
                showSidebar &&
                <div>
                    <div className="Header-menu-container-vertical">
                        <NavLink to={"/"}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active_link" : ""
                            }
                            onClick={toggleSideBar}
                        >HOME</NavLink>

                        <NavLink to={"/about_us"}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active_link" : ""
                            }
                            onClick={toggleSideBar}
                        >ABOUT US</NavLink>

                        <NavLink to={"/products"}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active_link" : ""
                            }
                            
                            onClick={toggleSideBar}
                            >PRODUCTS</NavLink>

                        <NavLink to={"/services"}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active_link" : ""
                            }
                            
                            onClick={toggleSideBar}
                            >SERVICES</NavLink>

                        <NavLink to={"/careers"} className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }
                        
                        onClick={toggleSideBar}>CAREERS</NavLink>

                        <NavLink to={"/contact_us"} className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active_link" : ""
                        }
                        
                        onClick={toggleSideBar}>CONTACT US</NavLink>
                    </div>
                </div>
            }

        </div>
    )
}

export default Header;